import PropTypes from "prop-types";
import { forwardRef } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
// routes
import { RouterLink } from "src/routes/components";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 80,
        height: 80,
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      <svg
        width="100%" 
        height="100%"
        viewBox="0 0 183 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.752 48H40.568L21.56 23.744L39.672 0.639999H28.856L13.816 20.096H9.656V0.639999H0.632V48H9.656V27.52H14.008L29.752 48ZM74.7405 30.208C74.7405 19.776 67.8925 12.864 57.5245 12.864C47.2205 12.864 40.3085 20.096 40.3085 30.976C40.3085 41.6 47.4125 48.64 57.9725 48.64C64.5005 48.64 69.8765 45.696 73.0125 41.088L66.2925 37.696C64.2445 40.192 61.3645 41.792 57.8445 41.792C52.7885 41.792 50.1645 38.72 49.5245 33.856H74.5485C74.6765 32.832 74.7405 31.424 74.7405 30.208ZM57.5245 19.712C62.2605 19.712 65.0125 22.72 65.6525 27.968H49.5245C50.2285 22.72 52.9805 19.712 57.5245 19.712ZM101.317 12.864C96.709 12.864 92.677 14.592 90.053 17.856L89.925 13.504H81.541V48H90.245V27.712C90.245 23.232 93.445 20.288 98.309 20.288C103.173 20.288 106.373 23.232 106.373 27.712V48H115.077V26.304C115.077 18.24 109.573 12.864 101.317 12.864ZM158.039 48H167.703L147.479 0.639999H139.095L118.87 48H128.535L132.375 38.592H154.199L158.039 48ZM135.447 31.104L143.319 11.84L151.191 31.104H135.447ZM173.195 48H182.219V0.639999H173.195V48Z"
          fill={PRIMARY_MAIN}
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;

import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
// hooks
import { useResponsive } from "src/hooks/use-responsive";
// _mock
import { _mock } from "src/_mock";
// assets
import Automate from "src/assets/images/Login/automate.png";
import Assure from "src/assets/images/Login/assure.png";
import Govern from "src/assets/images/Login/govern.png";
import Monitor from "src/assets/images/Login/monitor.png";
// components
import Logo from "src/components/logo";
//
import CarouselBasic2 from "../_common/carousel-basic-2";
import CarouselAnimation from "../_common/carousel-animation";

// ----------------------------------------------------------------------

const _carouselsExample = [
  {
    title: "KenAI Automate",
    coverUrl: Automate,
    description:
      "Automate pipelines for training, inference, and drift along with reusable components to reduce time to market",
  },
  {
    title: "KenAI Assure",
    coverUrl: Assure,
    description:
      "Model quality assurance with in-built test cases to help quantify the model quality and performance for real-world deployent",
  },
  {
    title: "KenAI Govern",
    coverUrl: Govern,
    description:
      "Comprehensive model explainations and governance to drive transparency and auditability of the models",
  },
  {
    title: "KenAI Monitor",
    coverUrl: Monitor,
    description:
      "Centralized dashboard to continuously monitor drift and service health parameters to ensure optimum model performance",
  },
].map((dict, index) => ({
  id: _mock.id(index),
  ...dict,
}));

// ----------------------------------------------------------------------

export default function AuthModernLayout({ children, image }) {
  const upMd = useResponsive("up", "md");

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: "auto",
        maxWidth: 600,
        px: { xs: 2, md: 8 },
      }}
    >
      <Logo
        sx={{
          mt: { xs: 2, md: 8 },
          mb: { xs: 6, md: 4 },
        }}
      />

      <Card
        sx={{
          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: "none" },
          overflow: { md: "unset" },
          bgcolor: { md: "background.default" },
        }}
      >
        {children}
      </Card>
    </Stack>
  );

  const renderSection = (
    <Stack flexGrow={1} sx={{ position: "relative" }}>
      <Box
        alt="auth"
        sx={{
          top: 16,
          left: 16,
          objectFit: "cover",
          position: "absolute",
          width: "calc(100% - 32px)",
          height: "calc(100% - 32px)",
        }}
      >
        <CarouselAnimation data={_carouselsExample} />
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: "100vh",
        position: "relative",
        "&:before": {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: "absolute",
          backgroundSize: "cover",
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundImage: "url(/assets/background/overlay_4.jpg)",
        },
      }}
    >
      {upMd && renderSection}

      {renderContent}
    </Stack>
  );
}

AuthModernLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
};

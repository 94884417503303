import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import constants from './constants';
import axios from 'axios';
//
import App from './App';

// ----------------------------------------------------------------------
axios.defaults.baseURL = constants.API_URL_DEV;
// axios.defaults.baseURL = constants.API_URL_PROD;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);

import { useMemo } from "react";
// routes
import { paths } from "src/routes/paths";
// locales
import { useLocales } from "src/locales";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import SvgColor from "src/components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <Iconify icon={name} width={25}/>
  // OR
  //
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const iconLocal = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  home: icon("solar:home-bold-duotone"),
  code: icon("solar:code-2-line-duotone"),
  monitor: icon("solar:monitor-camera-bold-duotone"),
  assure: icon("solar:medal-ribbons-star-bold-duotone"),
  automate: icon("solar:rocket-2-bold-duotone"),
  job: iconLocal("ic_job"),
  blog: iconLocal("ic_blog"),
  chat: iconLocal("ic_chat"),
  mail: iconLocal("ic_mail"),
  user: iconLocal("ic_user"),
  file: iconLocal("ic_file"),
  lock: iconLocal("ic_lock"),
  tour: iconLocal("ic_tour"),
  order: iconLocal("ic_order"),
  label: iconLocal("ic_label"),
  blank: iconLocal("ic_blank"),
  kanban: iconLocal("ic_kanban"),
  folder: iconLocal("ic_folder"),
  banking: iconLocal("ic_banking"),
  booking: iconLocal("ic_booking"),
  invoice: iconLocal("ic_invoice"),
  product: iconLocal("ic_product"),
  calendar: iconLocal("ic_calendar"),
  disabled: iconLocal("ic_disabled"),
  external: iconLocal("ic_external"),
  menuItem: iconLocal("ic_menu_item"),
  ecommerce: iconLocal("ic_ecommerce"),
  analytics: iconLocal("ic_analytics"),
  dashboard: iconLocal("ic_dashboard"),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t("overview"),
        items: [
          {
            title: t("home"),
            path: paths.dashboard.root,
            icon: ICONS.home,
          },
        ],
      },

      // DASHBOARD
      // ----------------------------------------------------------------------
      {
        subheader: t("dashboard"),
        items: [
          // AUTOMATE
          {
            title: t("automate"),
            path: paths.dashboard.automate.root,
            icon: ICONS.automate,
            children: [
              { title: t("pipelines"), path: paths.dashboard.automate.pipelines },
              {
                title: t("reusable components"),
                path: paths.dashboard.automate.reusableComponents,
              },
            ],
          },

          // ASSURE
          {
            title: t("assure"),
            path: paths.dashboard.assure.root,
            icon: ICONS.assure,
            children: [
              {
                title: t("quality assurance"),
                path: paths.dashboard.assure.qualityAssurance,
              },
              {
                title: t("MRO dashboard"),
                path: paths.dashboard.assure.mroDashboard,
              },
            ],
          },

          // GOVERN
          {
            title: t("govern"),
            path: paths.dashboard.govern.root,
            icon: ICONS.banking,
            children: [
              {
                title: t("model explaination"),
                path: paths.dashboard.govern.modelExplaination,
              },
            ],
          },

          // MONITOR
          {
            title: t("monitor"),
            path: paths.dashboard.monitor.root,
            icon: ICONS.monitor,
            children: [
              { title: t("model drift"), path: paths.dashboard.monitor.modelDrift },
              {
                title: t("data drift"),
                path: paths.dashboard.monitor.dataDrift,
              },
              { title: t("service health"), path: paths.dashboard.monitor.serviceHealth },
            ],
          },

          // COMPANION
          // {
          //   title: t("companion"),
          //   path: paths.dashboard.companion.root,
          //   icon: ICONS.code,
          //   children: [
          //     { title: t("code conversion"), path: paths.dashboard.companion.codeConversion },
          //   ],
          //   info: (
          //     <Label
          //       color="warning"
          //       startIcon={<Iconify icon="solar:test-tube-minimalistic-bold-duotone" />}
          //     >
          //       BETA
          //     </Label>
          //   ),
          // },
        ],
      },
      {
        subheader: t("management"),
        items: [
          // USER
          {
            title: t("user"),
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [{ title: t("list"), path: paths.dashboard.user.list }],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}

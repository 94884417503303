// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

// export const HOST_API = process.env.REACT_APP_HOST_API;
export const HOST_API = process.env.API_URL_DEV;
// export const HOST_API = process.env.API_URL_PROD;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;


// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

// LANDING PAGE
export const PATH_LANDING = paths.auth.jwt.login
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
// import { GuestGuard } from "src/auth/guard";
// layouts
import AuthModernLayout from "src/layouts/auth/modern";
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

// components
import { SplashScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

// MODERN
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

const ForgotPasswordModernPage = lazy(() =>
  import("src/pages/auth/modern/forgot-password")
);
const VerifyModernPage = lazy(() =>
  import("src/pages/auth/modern/verify")
);
const NewPasswordModernPage = lazy(() =>
  import("src/pages/auth/modern/new-password")
);

// ----------------------------------------------------------------------

const authJwt = {
  path: "jwt",
  element: (
    // <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    // </GuestGuard>
  ),
  children: [
    {
      path: "login",
      element: (
        <AuthModernLayout>
          <JwtLoginPage />
        </AuthModernLayout>
      ),
    },
    {
      path: "signup",
      element: (
        <AuthModernLayout title="Manage the job more effectively with Minimal">
          <JwtRegisterPage />
        </AuthModernLayout>
      ),
    },
    {
      element: (
        <AuthModernCompactLayout>
          <Outlet />
        </AuthModernCompactLayout>
      ),
      children: [
        { path: "forgot-password", element: <ForgotPasswordModernPage /> },
        { path: "new-password", element: <NewPasswordModernPage /> },
        { path: "verify", element: <VerifyModernPage /> },
      ],
    },
  ],
};

export const authRoutes = [
  {
    path: "auth",
    children: [authJwt],
  },
];

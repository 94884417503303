import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import("src/pages/dashboard/app"));
const ProjectDetailsPage = lazy(() =>
  import("src/pages/dashboard/home/details")
);
// USER
const UserCardsPage = lazy(() => import("src/pages/dashboard/user/cards"));
const UserListPage = lazy(() => import("src/pages/dashboard/user/list"));
const UserAccountPage = lazy(() => import("src/pages/dashboard/user/account"));
const UserCreatePage = lazy(() => import("src/pages/dashboard/user/new"));
const UserEditPage = lazy(() => import("src/pages/dashboard/user/edit"));
//AUTOMATE
const AutomatePipelines = lazy(() =>
  import("src/pages/dashboard/automate/pipelines")
);
const AutomateReusableComponents = lazy(() =>
  import("src/pages/dashboard/automate/reusable-components")
);
const AutomateComponentDetails = lazy(() =>
  import("src/pages/dashboard/automate/component-details")
);
//ASSURE
const AssureQualityAssurance = lazy(() =>
  import("src/pages/dashboard/assure/quality-assurance")
);
const AssureQualityResult = lazy(() =>
  import("src/pages/dashboard/assure/quality-result")
);
const AssureMRODashboard = lazy(() =>
  import("src/pages/dashboard/assure/mro-dashboard")
);
const AssureMROResult = lazy(() =>
  import("src/pages/dashboard/assure/mro-result")
);
//GOVERN
const GovernModelExplaination = lazy(() =>
  import("src/pages/dashboard/govern/model-explaination")
);
const GovernInsights = lazy(() =>
  import("src/pages/dashboard/govern/govern-insights")
);
//MONITOR
const MonitorModelDrift = lazy(() =>
  import("src/pages/dashboard/monitor/model-drift")
);
const MonitorDataDrift = lazy(() =>
  import("src/pages/dashboard/monitor/data-drift")
);
const MonitorDatadriftdetails = lazy(() =>
  import("src/pages/dashboard/monitor/data-drift-details-view")
);
const MonitorModeldriftdetails = lazy(() =>
  import("src/pages/dashboard/monitor/model-drift-details-view")
);
const MonitorServiceHealth = lazy(() =>
  import("src/pages/dashboard/monitor/service-health")
);

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: "home",
        children: [
          { element: <ProjectDetailsPage />, index: true },
          { path: ":projectId", element: <ProjectDetailsPage /> },
        ],
      },
      {
        path: "user",
        children: [
          { element: <UserListPage />, index: true },
          { path: "cards", element: <UserCardsPage /> },
          { path: "list", element: <UserListPage /> },
          { path: "new", element: <UserCreatePage /> },
          { path: ":id/edit", element: <UserEditPage /> },
          { path: "account", element: <UserAccountPage /> },
        ],
      },
      {
        path: "automate",
        children: [
          { element: <AutomatePipelines />, index: true },
          { path: "pipelines", element: <AutomatePipelines /> },
          {
            path: "reusable-components",
            element: <AutomateReusableComponents />,
          },
          {
            path: "reusable-components/:componentName",
            element: <AutomateComponentDetails />,
          },
          // { path: "new", element: <ProductCreatePage /> },
          // { path: ":id/edit", element: <ProductEditPage /> },
        ],
      },
      {
        path: "assure",
        children: [
          { element: <AssureQualityAssurance />, index: true },
          { path: "quality-assurance", element: <AssureQualityAssurance /> },
          {
            path: "quality-assurance/quality-result",
            element: <AssureQualityResult />,
          },
          { path: "mro-dashboard", element: <AssureMRODashboard /> },
          {
            path: "mro-dashboard/mro-result",
            element: <AssureMROResult />,
          },
          {
            path: "mro-dashboard/mro-result/:id",
            element: <AssureMROResult />,
          },

          // { path: "new", element: <ProductCreatePage /> },
          // { path: ":id/edit", element: <ProductEditPage /> },
        ],
      },
      {
        path: "govern",
        children: [
          { element: <GovernModelExplaination />, index: true },
          { path: "model-explaination", element: <GovernModelExplaination /> },
          { path: "govern-insights", element: <GovernInsights /> },
          // { path: "new", element: <ProductCreatePage /> },
          // { path: ":id/edit", element: <ProductEditPage /> },
        ],
      },
      {
        path: "monitor",
        children: [
          { element: <MonitorModelDrift />, index: true },
          { path: "model-drift", element: <MonitorModelDrift /> },
          { path: "data-drift", element: <MonitorDataDrift /> },
          {
            path: "model-drift/details",
            element: <MonitorModeldriftdetails />,
          },
          { path: "data-drift/details", element: <MonitorDatadriftdetails /> },
          { path: "service-health", element: <MonitorServiceHealth /> },
          // { path: ":id/edit", element: <ProductEditPage /> },
        ],
      },
    ],
  },
];

import axios from "axios";
// config
// import { HOST_API } from "src/config-global";
import constants from "src/constants";
// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: constants.API_URL_PROD });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  console.log(config);
  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: "/api/chat",
  kanban: "/api/kanban",
  calendar: "/api/calendar",
  auth: {
    me: "/api/user/me",
    login: "/api/user/login",
    register: "/api/user/invite/accept",
    getInvitedUser: "/api/user/get_invited_user",
    getForgotPasswordUserData: "/api/user/get_forgot_password_user_data",
    forgotPassword: "/api/user/forgot_password",
    changePassword: "/api/user/reset_password",
  },
  configuration: {
    get: "/api/users/get_configurations",
    updateAzureDevopsConfigure: "/api/user/save_azure_devops_configurations",
    updateAWSCodeCommitConfigure:
      "/api/user/save_aws_codecommit_configurations",
    updateSnowflakeConfigure: "/api/user/save_snowflake_configurations",
    updateRedshiftConfigure: "/api/user/save_redshift_configurations",
    updateOpenaiConfigure: "/api/users/openai-configure-submit",
  },
  user: {
    getList: "/api/user/list",
    updateRole: "/api/user/update_role",
    inviteUser: "/api/user/invite",
  },
  home: {
    getListandOverview: "/api/projects/get-summary-table-and-overview",
    createProject: "/api/projects/create",
    projectDetails: "/api/projects/get-project-details",
    getDatasetsandModels: "/api/projects/get-datasets-models-info",
    updateProjectDetails: "/api/projects/update-project-details-v2",
  },
  automate: {
    pipelines: {
      getList: "/api/automate/pipelines/list",
    },
  },
  reusablecomponents: {
    getDataRegistryList: "/api/automate/get_dataset_reusable_info",
    getModelRegistryList: "/api/automate/get_model_reusable_info",
    getBatchEndpointList: "/api/automate/infra/endpoints/batch",
    getRealtimeEndpointList: "/api/automate/infra/endpoints/realtime",
  },
  assure: {
    mroDashboard: {
      setInstance: "/api/assure/set_user_client_instance",
      getList: "/api/assure/aws/scorecard/get-scorecard-summary",
      getReportWithParams: "/api/assure/aws/scorecard/scorecard-results-tab",
      getReport: "/api/assure/aws/scorecard/get-scorecard-results",
      getNarrative: "/api/assure/aws/scorecard/getnarrative",
      getDatasetsAndModels: "/api/assure/get-projects-datasets-models",
      getBucketsAndProjectid: "/api/assure/aws/get-bucket-project-list",
      getDataFeatures: "/api/assure/aws/scorecard/get-scorecard-data-columns",
      getScorecardDetails: "/api/assure/aws/scorecard/get-scorecard-details",
    },
  },
  mail: {
    list: "/api/mail/list",
    details: "/api/mail/details",
    labels: "/api/mail/labels",
  },
  post: {
    list: "/api/post/list",
    details: "/api/post/details",
    latest: "/api/post/latest",
    search: "/api/post/search",
  },
  product: {
    list: "/api/product/list",
    details: "/api/product/details",
    search: "/api/product/search",
  },
  monitor: {
    modeldriftruns: "/api/model_drift/runs",
    datadriftruns: "/api/data_drift/runs",
    datadriftdetails: {
      verify: "/api/data_drift/verify",
      lastrun: "/api/data_drift/last_run_status_details",
      driftmagnitude: "/api/data_drift/get_drift_magnitude",
      numericalfeatures: "/api/data_drift/last_run_numerical_feature_details",
      categoricalfeatures:
        "/api/data_drift/last_run_categorical_feature_details",
      getnarrative: "/api/data_drift/getnarrative",
      driftfeatures: "/api/data_drift/get_feature_drift_plot",
    },
    modeldriftdetails: {
      lastrun: "/api/model_drift/last_run_status_details",
      driftmagnitude: "/api/model_drift/get_drift_magnitude",
      matricscore: "/api/model_drift/get_metrics_score_plot",
      lastrunplot: "/api/model_drift/get_model_last_run_plot",
      getnarrative: "/api/model_drift/getnarrative",
      predictiondata: "/api/model_drift/get_prediction_data",
    },
    servicehealth: {
      graph: "/api/service_health/graph?api_name=",
      lastrunmetrics: "/api/service_health/last_run_metrics",
      healthanalyser: "/api/service_health/run_health_analyzer",
    },
    createdrift: {
      getprojectslist: "/api/automate/get-projects-list",
      listdataassets: "/api/data_drift/list_data_assets",
      create: "/api/data_drift/create",
    },
    modeldriftrun: {
      listmodelassets: "/api/model_drift/list_model_assets",
      listcolumns: "/api/model_drift/list_columns",
      fieldtype: "/api/model_drift/list_columns?field_type=true",
      create: "/api/model_drift/create",
    },
  },
  govern: {
    modelexplanations: {
      datasetcolumns: "/api/aws/govern/get-selected-dataset-columns",
      databricksmodel: "/api/aws/get-selected-model-datasets",
      dataframe: "/api/aws/govern/submit-govern",
      table: "/api/aws/get-models-dataset-list",
    },
    featureimportance: {
      cumulativefeature: "/api/azure/govern/cumulativefeature",
      featureplot: "/api/azure/govern/featureplot",
    },
    partialdependence: {
      checkclassifier: "/api/azure/govern/checkclassifier",
      list: "/api/azure/govern/list",
      depend: "/api/azure/govern/depend",
      topcolumns: "/api/azure/govern/topcolumns",
    },
    whatifanalysis: {
      rangeof: "/api/azure/govern/rangeof",
      classes: "/api/azure/govern/nclasses",
      whatif: "/api/azure/govern/whatif",
      predictions: "/api/azure/govern/whatifpred",
      imp: "/api/azure/govern/localimp",
    },
    modelmetadata: {
      model: "/api/azure/govern/model",
    },
  },
};

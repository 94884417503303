import { Navigate, useRoutes } from "react-router-dom";
// layouts
// import MainLayout from 'src/layouts/main';
// config
import { PATH_LANDING } from 'src/config-global';
//
// import { HomePage } from './main';
import { authRoutes } from "./auth";
// import { authDemoRoutes } from "./auth-demo";
import { dashboardRoutes } from "./dashboard";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={PATH_LANDING} replace />,
    },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    // {
    //   path: '/',
    //   element: (
    //     <MainLayout>
    //       <HomePage />
    //     </MainLayout>
    //   ),
    // },

    // Auth routes
    ...authRoutes,
    // ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // No match 404
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
